import ListCategories from '../components/ListCategories/ListCategories'

const Categories = () => {
    return (
        <>
            <ListCategories/>
        </>
    )
}

export default Categories
